@charset "utf-8";

@import "./themes/nuclear_variables";
@import "bulma";
@import "bulma-switch";
@import "bulma-timeline";
@import "bulma-o-steps";
@import "./themes/nuclear_custom";

// CUSTOM
.has-border-bottom {
  border-bottom: 1px solid $border;
}

.mb-npx {
  margin-bottom: -1px;
}

.is-order-1 {
  order: 1;
}

.is-order-2 {
  order: 2;
}

.is-order-3 {
  order: 3;
}

#mainNavbar {
  padding-bottom: 5rem;
}

@include tablet {
  #mainNavbar {
    padding-bottom: 0;
  }
}

.parallax {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@include desktop {
  .parallax {
    background-attachment: fixed;
  }

  .navbar-scrolled-down {
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
  }

  .navbar-scrolled-up {
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.20);
  }

  .is-order-1-desktop {
    order: 1;
  }

  .is-order-2-desktop {
    order: 2;
  }

  .is-order-3-desktop {
    order: 3;
  }
}

.switch[type=checkbox]:focus + label:before,
.switch[type=checkbox]:focus + label:after {
    outline: 0;
}

.gradient-border {
  border: none;
}

.gradient-border:after {
  content: '';
  position: absolute;
  top: calc(-1 * 3px);
  left: calc(-1 * 3px);
  height: calc(100% + 3px * 2);
  width: calc(100% + 3px * 2);
  background: linear-gradient(60deg, $yellow, $orange, $purple, $blue, $turquoise, $green, $red);
  border-radius: $radius-large;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hero-box {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  border-radius: $radius-large;
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

.is-line-through {
  text-decoration: line-through;
}
